<template>
    <div class="bg-gray-100 overflow-hidden h-full">
        <div class="m-8 h-full">
            <h2
                class="max-w-6xl -mx-8 mx-auto mt-8 px-4 text-lg leading-6 text-left font-medium text-gray-900 sm:px-6 lg:px-8"
            >
                {{ $t("billing.payment_records") }}
            </h2>
            <!-- Activity table (small breakopoint and up) -->
            <div
                class="flex flex-col pb-8 bg-white mt-4 rounded-xl h-1/2 justify-between"
            >
                <div class="max-w-6xl block">
                    <div class="flex flex-col">
                        <div
                            class="align-middle min-w-full overflow-x-auto shadow overflow-hidden rounded-t-xl"
                        >
                            <table
                                class="table-fixed min-w-full divide-y divide-gray-200 rounded-t-xl"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                        >
                                            {{ $t("billing.payment_date") }}
                                        </th>
                                        <th
                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                        >
                                            {{ $t("billing.amount") }}
                                        </th>
                                        <th
                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm border-r font-medium text-gray-500 tracking-wider"
                                        >
                                            {{ $t("billing.description") }}
                                        </th>
                                        <th
                                            v-if="this.user.role !== 1"
                                            class="w-1/8 pl-4 py-5 bg-gray-50 text-left text-sm font-medium text-gray-500 tracking-wider"
                                        >
                                            {{ $t("billing.receipt") }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    v-if="!isLoading"
                                    class="bg-white divide-y divide-gray-200"
                                >
                                    <tr
                                        class="bg-white text-left"
                                        v-for="(record, index) in this.data
                                            .list"
                                        :key="index"
                                    >
                                        <td class="border-r pl-4 py-3">
                                            {{ record.payment_date }}
                                        </td>
                                        <td class="border-r pl-4 py-3">
                                            {{ record.topup_amt }}
                                        </td>
                                        <td class="border-r pl-4 py-3">
                                            <div v-if="record.description">
                                                {{ record.description }}
                                            </div>
                                            <div
                                                v-if="!record.description"
                                            ></div>
                                        </td>
                                        <td
                                            v-if="this.user.role !== 1"
                                            class="pl-4"
                                        >
                                            <button
                                                v-if="record.state === 1"
                                                @click="
                                                    getInvoiceContent(record.id)
                                                "
                                                class="text-cyan-600 hover:text-cyan-900"
                                            >
                                                {{ $t("billing.download") }}
                                            </button>
                                            <span v-if="record.state === 0">{{
                                                $t("billing.pending")
                                            }}</span>
                                            <span v-if="record.state === 2">{{
                                                $t("billing.rejected")
                                            }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div v-if="this.data.total_page >= 1" class="mt-8 text-right">
                    {{ $t("page1") }} {{ currentPage }}/{{
                        this.data.total_page
                    }}
                    {{ $t("page2") }}
                    <button
                        @click="
                            currentPage > 1
                                ? [(currentPage -= 1), this.getInfo()]
                                : ''
                        "
                        type="button"
                        class="ml-10 px-6 py-1 border rounded-md"
                    >
                        {{ $t("button.prev_pg") }}
                    </button>
                    <button
                        @click="
                            currentPage < this.data.total_page
                                ? [(currentPage += 1), this.getInfo()]
                                : ''
                        "
                        type="button"
                        class="mx-8 px-8 py-1 border rounded-md"
                    >
                        {{ $t("button.next_pg") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { jsPDF } from "jspdf"

export default {
    name: "TransactionRecords",
    data() {
        return {
            data: {},
            currentPage: 1,
            isLoading: true
        }
    },
    props: {
        user: { required: true, type: Object },
        enterprise: { required: false }
    },
    watch: {
        enterprise(to, from) {
            if (this.user.role === 1) {
                this.getInfo()
            }
        }
    },
    methods: {
        async getInfo() {
            const token = await this.$auth.getTokenSilently()
            let url = ""
            if (this.user.role === 1) {
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/credit/topup/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&enterprise_id=${this.enterprise}&page=` +
                    this.currentPage
            } else
                url =
                    process.env.VUE_APP_API_BASE_URL +
                    `/credit/topup/list?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&page=` +
                    this.currentPage
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(response => {
                    this.data = response.data.Data
                    this.isLoading = false
                })
        },
        async getInvoiceContent(id) {
            const token = await this.$auth.getTokenSilently()
            const url =
                process.env.VUE_APP_API_BASE_URL +
                `/credit/topup/invoice?user_id=${this.$auth.user.value.sub}&lang=${this.$i18n.locale}&id=${id}`
            this.$axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}` // send the access token through the 'Authorization' header
                    }
                })
                .then(res => {
                    this.pdfData = res.data.Data
                    this.createPDF()
                })
        },
        createPDF() {
            const logo = require("@/assets/flow_s.png")
            let pdfName =
                "receipt_20" +
                this.pdfData.payment_date.split(" ")[0].split("/")[2] +
                "_" +
                this.pdfData.payment_date.split("/")[1] +
                "_" +
                this.pdfData.payment_date.split("/")[0]
            let doc = new jsPDF({ format: "a4" })
            let imgLogo = new Image()
            imgLogo.src = logo
            doc.addImage(imgLogo, "PNG", 20, 15, 32, 15)
            doc.setFont("arial", "bold")
            doc.setFontSize(26)
            doc.text("Payment Receipt", 115, 30)

            doc.setFontSize(9)
            doc.setFont("arial", "normal")

            doc.text(
                "Flow Entertainment Limited\n\nUnit 2202, 22/F\n" +
                    "Causeway Bay Plaza I\n" +
                    "489 Hennessy Road\n" +
                    "Causeway Bay, Hong Kong",
                20,
                60
            )
            doc.text(
                this.pdfData.payment_date +
                    "\n" +
                    this.pdfData.payment_method +
                    "\n" +
                    this.pdfData.transaction_id +
                    "\n" +
                    this.pdfData.account_id,
                150,
                60
            )

            doc.text(
                "Rambler Oasis Hotel\n" +
                    this.pdfData.address[0] +
                    " \n" +
                    this.pdfData.address[1] +
                    "\n" +
                    "\n" +
                    this.$auth.user.value.nickname +
                    "\n",
                50,
                90
            )

            doc.text("Company credits top-up", 25, 136)
            doc.text(this.pdfData.amount, 195, 136, null, null, "right")

            doc.setFont("arial", "bold")
            doc.text(
                "Payment Date:\n" +
                    "Payment Method:\n" +
                    "Transaction ID:\n" +
                    "Account ID:",
                115,
                60
            )

            doc.text("To:\n" + "Attn:\n" + "\n" + "\n" + "Attn:\n", 20, 90)

            doc.setFillColor(225, 250, 250)
            doc.rect(20, 120, 180, 10, "FD")
            // doc.cell(20, 120, 170, 10, "Description")
            doc.text("Description", 25, 126)
            doc.text("Amount", 195, 126, null, null, "right")

            doc.rect(20, 130, 180, 10)

            doc.setFontSize(11)
            doc.text(
                "Paid\n" + "\n" + this.pdfData.amount,
                200,
                90,
                null,
                null,
                "right"
            )

            doc.setFont("arial", "regular")
            doc.text("1", 200, 285)

            doc.line(20, 160, 200, 160)

            doc.save(pdfName + ".pdf")
        }
    },
    mounted() {
        this.getInfo()
    }
}
</script>

<style scoped></style>
